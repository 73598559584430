import tw, { css, styled } from 'twin.macro';

export const Box = styled.div`
    ${tw`bg-white dark:bg-black border-2 border-vault-dark rounded-lg`}
`;

export const SecondaryBox = styled.div`
    ${tw`rounded-lg relative bg-white border border-secondary/30 dark:(border-none bg-black) overflow-clip backdrop-blur-2xl`}
`;

export const simpleBoxStyles = css`
    ${tw`rounded-lg relative bg-white border border-secondary/30 dark:(border-none bg-cultured/5) overflow-clip backdrop-blur-2xl`}
    &::before {
        content: '';
        background: linear-gradient(
            to left,
            #ffffff33,
            #ffffff0f,
            #ffffff0d,
            #ffffff1c
        );
        mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        ${tw`absolute pointer-events-none rounded-lg p-[1px] inset-0`}
    }
`;

export const SimpleBox = styled.div`
    ${simpleBoxStyles}
`;

export interface SimpleButtonProps {
    $outline?: boolean;
    $size?: 'xs' | 'small' | 'full';
    $variant?: 'swap' | 'transparent-outline';
}

export const SimpleButton = styled.button<SimpleButtonProps>`
    ${tw`text-sm font-medium cursor-pointer`}
    ${({ $outline }) =>
        $outline
            ? tw`border-2 border-vault-dark text-black dark:text-white hover:border-transparent hover:bg-gradient-to-br hover:from-vault-light hover:to-vault-dark`
            : tw`bg-gradient-to-br from-vault-light to-vault-dark text-white hover:from-vault-light/90 hover:to-vault-dark/90`};

    ${({ $size }) => $size === 'xs' && tw`rounded-full px-2 py-1`};
    ${({ $size }) => $size === 'small' && tw`px-3 py-2 rounded-lg`};
    ${({ $size }) => $size === 'full' && tw`w-full px-3 py-1 rounded-lg`};
    ${({ $size }) => !$size && tw`py-3 px-8 rounded-full`};
    ${({ $variant }) =>
        $variant === 'swap' && tw`rounded-xl h-10 w-full p-0 text-sm mt-0.5`}

    ${({ $variant }) =>
        $variant === 'transparent-outline' &&
        tw`transition-colors bg-none bg-white/80 text-secondary dark:(bg-transparent text-cultured) backdrop-blur-2xl ring-1 ring-secondary/30 hover:(bg-white dark:bg-cultured/5)`}

    &:disabled {
        ${tw`cursor-not-allowed bg-none bg-gray-500 dark:(bg-cultured/60 text-black/60)`}
        ${({ $variant }) =>
            $variant === 'swap' &&
            tw`backdrop-blur-2xl bg-black/10 dark:bg-cultured/5! text-secondary!`}
    }
`;
