import React from 'react';
import { Layout } from '../../Layout';
import { SocialIcons } from './SocialIcons';
import Background from './Background';
import { Navbar } from '../../site/Navbar';

interface Props {
    children: React.ReactNode;
}

export const SiteLayout: React.FC<Props> = ({ children }: Props) => {
    return (
        <div>
            <Background />
            <div tw="z-10 py-20 min-h-screen">
                <Layout>
                    <div tw="w-full">
                        <Navbar />
                        {children}
                        <SocialIcons />
                    </div>
                </Layout>
            </div>
        </div>
    );
};
