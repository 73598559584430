import React from 'react';
import { css, keyframes } from 'styled-components';
import { theme } from 'twin.macro';
import BackgroundImg from './Background.svg';

export default function Background() {
    return (
        <div tw="fixed h-screen w-screen overflow-clip -z-10">
            <BackgroundImg
                tw="w-[200%] h-[200%] absolute -left-1/2 -top-[30%] overflow-clip -z-20 opacity-0 dark:opacity-40 transition-all"
                css={css`
                    animation: ${keyframes`
                                from {
                                    transform: rotate(0deg);
                                }
                                to {
                                    transform: rotate(360deg);
                                }
                            `} 300s linear infinite;
                `}
            />
            <div
                tw="absolute top-0 left-0 -z-10 h-full w-full opacity-20 dark:bg-[linear-gradient(180deg, #c94b107d 0%, rgba(62, 125, 255, 0) 100%)]"
                css={css`
                    background-image: linear-gradient(
                        180deg,
                        ${theme`colors.vault.light`} 0%,
                        ${theme`colors.transparent`} 100%
                    );
                `}
            />
        </div>
    );
}
