import React from 'react';
import {
    FaSpinner,
    FaCheckCircle,
    FaExclamationCircle,
    FaExclamationTriangle,
    FaTimesCircle,
} from 'react-icons/fa';
import tw, { css } from 'twin.macro';

interface Props {
    variant?: 'success' | 'error' | 'warning' | 'info' | 'loading';
    title: string;
    children?: React.ReactNode;
}

export const ToastMessage: React.FC<Props> = ({
    title,
    children,
    variant = 'info',
}) => {
    const icon =
        variant === 'success' ? (
            <FaCheckCircle tw="text-green-500" />
        ) : variant === 'error' ? (
            <FaTimesCircle tw="text-red-500" />
        ) : variant === 'warning' ? (
            <FaExclamationTriangle tw="text-yellow-500" />
        ) : variant === 'info' ? (
            <FaExclamationCircle tw="text-blue-500" />
        ) : variant === 'loading' ? (
            <FaSpinner tw="animate-spin" />
        ) : null;

    return (
        <div
            tw="flex items-center gap-2"
            css={css`
                a {
                    ${tw`text-vault-dark hover:underline`}
                }
            `}
        >
            <div
                tw="w-4 h-4 shrink-0"
                css={css`
                    & > svg {
                        ${tw`w-4 h-4`}
                    }
                `}
            >
                {icon}
            </div>
            <div>
                <span tw="font-medium">{title}</span>
                <div>{children}</div>
            </div>
        </div>
    );
};
