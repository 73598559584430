import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
    Signer,
    TransactionInstruction,
    VersionedTransaction,
} from '@solana/web3.js';
import { useCallback } from 'react';
import { createVersionedTransaction } from '../helpers/transaction';
import { toast } from 'sonner';
import { useQueryClient } from '@tanstack/react-query';
import { PublicKey } from '@saberhq/solana-contrib';
import { uniqWith } from 'lodash';
import * as React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { ToastMessage } from '../components/ToastMessage';

const makeURL = (tx: VersionedTransaction) => {
    const url = new URL('https://explorer.solana.com/tx/inspector');
    url.searchParams.set('cluster', 'mainnet-beta');
    url.searchParams.set('signatures', encodeURIComponent(JSON.stringify([])));
    url.searchParams.set(
        'message',
        Buffer.from(tx.message.serialize()).toString('base64'),
    );
    return url;
};

const makeTXUrl = (hash: string) => {
    return `https://solscan.io/tx/${hash}`;
};

export const useSendAndConfirmTransaction = () => {
    const { connection } = useConnection();
    const { wallet, publicKey } = useWallet();
    const client = useQueryClient();
    return useCallback(
        async (
            title: string,
            ixs: TransactionInstruction[],
            signers?: Signer[],
        ) => {
            if (!wallet || !publicKey) {
                return;
            }
            const toastId = toast(
                <ToastMessage variant="loading" title={title}>
                    Preparing transaction...
                </ToastMessage>,
                {
                    duration: 999999,
                },
            );
            const { transaction, latestBlockhash } =
                await createVersionedTransaction(connection, ixs, publicKey);
            if (signers) {
                transaction.sign(signers);
            }
            console.log(
                `${title} -  Inspect TX:`,
                makeURL(transaction).toString(),
            );
            toast(
                <ToastMessage variant="loading" title={title}>
                    Awaiting wallet signature...
                </ToastMessage>,
                { id: toastId },
            );
            let hash: string;
            try {
                hash = await wallet.adapter.sendTransaction(
                    transaction,
                    connection,
                );
            } catch (e) {
                toast(
                    <ToastMessage variant="error" title={title}>
                        {(e as Error)?.message ?? 'Transaction failed.'}
                    </ToastMessage>,
                    {
                        id: toastId,
                        duration: 5_000,
                    },
                );
                return;
            }
            toast(
                <ToastMessage variant="loading" title={title}>
                    Waiting for confirmation...{' '}
                    <a href={makeTXUrl(hash)} target="_blank" rel="noreferrer">
                        <FaExternalLinkAlt />
                        View TX
                    </a>
                </ToastMessage>,
                {
                    id: toastId,
                },
            );
            const result = await connection.confirmTransaction(
                { signature: hash, ...latestBlockhash },
                'confirmed',
            );

            if (!result.value.err) {
                const msg = transaction.message;
                const changedAccounts = new Set(
                    uniqWith(
                        [
                            ...msg.staticAccountKeys.map((k, i) =>
                                msg.isAccountWritable(i) ? k : null,
                            ),
                            ...(msg.getAccountKeys().accountKeysFromLookups
                                ?.writable ?? []),
                        ].filter((x): x is PublicKey => !!x),
                        (a, b) => a.equals(b),
                    ).map((s) => s.toString()),
                );
                // refetch all queries that depend on the changed accounts
                void client.refetchQueries({
                    predicate: (query) => {
                        const [first, next] = query.queryKey;
                        const nextPubKey = (
                            next as { publicKey?: string } | undefined
                        )?.publicKey;
                        if (
                            first === 'parsedAccountData' &&
                            nextPubKey &&
                            changedAccounts.has(nextPubKey)
                        ) {
                            return true;
                        }
                        return false;
                    },
                });
                toast(
                    <ToastMessage variant="success" title={title}>
                        Confirmed!{' '}
                        <a
                            href={makeTXUrl(hash)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaExternalLinkAlt />
                            View TX
                        </a>
                    </ToastMessage>,
                    {
                        id: toastId,
                        duration: 5000,
                    },
                );
            } else {
                toast(
                    <ToastMessage variant="error" title={title}>
                        Transaction failed.{' '}
                        <a
                            href={makeTXUrl(hash)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaExternalLinkAlt />
                            View TX
                        </a>
                    </ToastMessage>,
                    {
                        id: toastId,
                        duration: 5000,
                    },
                );
            }
            return hash;
        },
        [client, connection, publicKey, wallet],
    );
};
