import { useCallback, useMemo } from 'react';
import useTokenBalance from '../../../hooks/useTokenBalance';
import { getQuarryMinerAddresses } from '../../../hooks/useQuarry';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { POOLS } from '../pages/PoolsPage/pools';
import { useQuery } from '@tanstack/react-query';
import { QUARRY_CODERS } from '@quarryprotocol/quarry-sdk';
import { makeCalculateClaimableAmount } from '../pages/PoolsPage/hooks/useQuarryClaim';
import { POINTS_TOKEN } from '../../../constants';
import invariant from 'tiny-invariant';
import { TokenAmount } from '@saberhq/token-utils';
import { sum } from 'lodash';
import { PublicKey } from '@saberhq/solana-contrib';

export const useUserPools = () => {
    const { publicKey } = useWallet();
    // addresses to check
    const addresses = useMemo(() => {
        if (!publicKey) {
            return [];
        }
        return POOLS.map((poolInfo) => {
            const minerAddresses = getQuarryMinerAddresses({
                poolInfo,
                owner: publicKey,
            });
            const vptsMiner = minerAddresses.pools.find((pool) =>
                pool.rewardsToken.equals(POINTS_TOKEN),
            );
            if (!vptsMiner) {
                return null;
            }
            return {
                miner: vptsMiner.miner,
                quarry: vptsMiner.quarry,
            };
        }).filter((x): x is { miner: PublicKey; quarry: PublicKey } => !!x);
    }, [publicKey]);
    const { connection } = useConnection();

    return useQuery({
        queryKey: ['userPools', { owner: publicKey?.toString() }],
        queryFn: async () => {
            invariant(publicKey, 'publicKey');
            const calculators = await Promise.all(
                addresses.map(async ({ miner, quarry }) => {
                    if (!miner) {
                        return null;
                    }
                    const [minerData, quarryData] =
                        await connection.getMultipleAccountsInfo([
                            miner,
                            quarry,
                        ]);
                    if (!minerData || !quarryData) {
                        return null;
                    }
                    const minerParsed = QUARRY_CODERS.Mine.accounts.miner.parse(
                        minerData.data,
                    );
                    const quarryParsed =
                        QUARRY_CODERS.Mine.accounts.quarry.parse(
                            quarryData.data,
                        );
                    const { get } = makeCalculateClaimableAmount({
                        rewardsToken: POINTS_TOKEN,
                        quarryData: quarryParsed,
                        minerData: minerParsed,
                    });
                    return {
                        miner: minerData,
                        quarry: quarryData,
                        get,
                    };
                }),
            );
            return {
                calculate: () => {
                    return sum(
                        calculators.map((c) => {
                            if (!c) {
                                return 0;
                            }
                            return c.get();
                        }),
                    );
                },
            };
        },
        enabled: !!publicKey,
    });
};

export const useUserPoints = () => {
    const { data: pointsBalance } = useTokenBalance(POINTS_TOKEN.mintAccount);
    const { data: pools } = useUserPools();
    return useCallback(() => {
        const base = pointsBalance
            ? new TokenAmount(POINTS_TOKEN, pointsBalance.toString()).asNumber
            : 0;
        return base + (pools ? pools.calculate() : 0);
    }, [pointsBalance, pools]);
};
