import * as React from 'react';
import { BaseWalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { FaChevronDown } from 'react-icons/fa';
import tw, { css, styled } from 'twin.macro';

const GradientLabel = styled.span`
    ${tw`bg-gradient-to-br from-vault-light to-vault-dark flex gap-0.5`}
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const LABELS = {
    'change-wallet': 'Change wallet',
    connecting: 'Connecting ...',
    'copy-address': 'Copy address',
    copied: 'Copied',
    disconnect: 'Disconnect',
    'has-wallet': 'Connect Wallet',
    'no-wallet': (
        <GradientLabel>
            Connect<span className="no-mobile">Wallet</span>
        </GradientLabel>
    ),
} as const;

export const WalletButton: React.FC = () => {
    const { publicKey } = useWallet();

    return (
        <div
            css={css`
                .no-mobile {
                    ${tw`hidden sm:block`}
                }
            `}
        >
            <BaseWalletMultiButton
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                labels={LABELS}
            >
                {publicKey ? (
                    <FaChevronDown tw="text-black/80 dark:text-cultured/80" />
                ) : null}
            </BaseWalletMultiButton>
        </div>
    );
};
