import { ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
    ComputeBudgetProgram,
    Connection,
    PublicKey,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
} from '@solana/web3.js';
import invariant from 'tiny-invariant';

const getCUsForTx = async (
    connection: Connection,
    latestBlockhash: Awaited<ReturnType<typeof connection.getLatestBlockhash>>,
    txs: TransactionInstruction[],
    payerKey: PublicKey,
) => {
    const messageV0 = new TransactionMessage({
        payerKey,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: txs,
    }).compileToV0Message();
    const transaction = new VersionedTransaction(messageV0);
    const simulation = await connection.simulateTransaction(transaction);
    const CUs =
        !simulation.value.unitsConsumed || simulation.value.unitsConsumed == 0
            ? 1.4e6
            : simulation.value.unitsConsumed;
    //console.log('Estimated CUs:', CUs);
    return CUs;
};

export const createVersionedTransaction = async (
    connection: Connection,
    txs: TransactionInstruction[],
    payerKey: PublicKey,
    addCUs?: boolean,
    minimumCU?: number,
) => {
    const latestBlockhash = await connection.getLatestBlockhash('finalized');

    if (addCUs) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const estimatedCUs = await getCUsForTx(
            connection,
            latestBlockhash,
            txs,
            payerKey,
        );
        const CUs = Math.max(minimumCU ? minimumCU : 30000, estimatedCUs); // Always have at least 30k or minimumCU CU
        txs.unshift(
            ComputeBudgetProgram.setComputeUnitLimit({
                units: CUs + 10000, // +10k for safety and the CU limit ix itself
            }),
        );
    }
    const messageV0 = new TransactionMessage({
        payerKey,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: txs,
    }).compileToV0Message();
    const transaction = new VersionedTransaction(messageV0);
    return { transaction, latestBlockhash };
};

export const dedupeDuplicateATAIxs = (
    ixs: TransactionInstruction[],
): TransactionInstruction[] => {
    const seenATAs = new Set<string>();
    return ixs
        .map((ix) => {
            const programId = ix.programId;
            if (programId.equals(ASSOCIATED_TOKEN_PROGRAM_ID)) {
                const ataKey = ix.keys[1]?.pubkey.toString();
                invariant(ataKey, 'ata key must exist');
                if (seenATAs.has(ataKey)) {
                    return null;
                }
                seenATAs.add(ataKey);
            }
            return ix;
        })
        .filter((ix): ix is TransactionInstruction => !!ix);
};
