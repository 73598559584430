import { PublicKey } from '@solana/web3.js';

/**
 * @warning For end-user display purposes only. It loses precision and does rounding
 *
 * There is padding
 *
 * If (Whole number digits > softMaximumSignificantDigits)
 *   Rounds to whole number towards 0
 * Otherwise
 *   Defaults to token.decimals, but respects numberFormatOptions as an override
 *
 * Example for softMaximumSignificantDigits = 7 and token with 6 maxDigits
 *
 * 1234.87654321 => 1234.876 (7 significant figures)
 * 1234567.87654321 => 1234567 (rounded down)
 * 123456789.321 => 123456789
 * 1.87654321 => 1.876543
 */
export const formatDisplayWithSoftLimit = (
    float: number,
    maxDecimals: number,
    softMaximumSignificantDigits = 7,
    numberFormatOptions?: Intl.NumberFormatOptions,
    locale?: string,
): string => {
    if (
        Number.isNaN(softMaximumSignificantDigits) ||
        softMaximumSignificantDigits <= 0
    ) {
        throw new Error('softMaximumSignificantDigits must be greater than 0');
    }

    // Round to integer if there are enough whole digits
    const dropDecimalsAfter = Math.pow(10, softMaximumSignificantDigits - 1);

    if (float >= dropDecimalsAfter) {
        // Round down to display integer amount
        const wholeNumberFormatOptions: Intl.NumberFormatOptions =
            Object.assign({}, numberFormatOptions, {
                maximumFractionDigits: 0,
            });
        return Math.floor(float).toLocaleString(
            locale,
            wholeNumberFormatOptions,
        );
    }

    // Round to maxDecimals if too small
    const sigDerivedDigitsOnRight = Math.max(
        0,
        softMaximumSignificantDigits -
            Math.floor(Math.log10(Math.abs(float))) -
            1,
    );

    const digitsOnRight = Math.max(
        0,
        Math.min(maxDecimals, sigDerivedDigitsOnRight),
    );
    const flooredToPrecision =
        Math.floor(float * 10 ** digitsOnRight) / 10 ** digitsOnRight;

    const maxFormatOptions: Intl.NumberFormatOptions = Object.assign(
        {
            minimumFractionDigits: digitsOnRight,
            maximumFractionDigits: digitsOnRight,
        },
        numberFormatOptions,
    );
    return flooredToPrecision.toLocaleString(locale, maxFormatOptions);
};
export const formatNumberWithSISuffix = (num: number) => {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast((item) => Math.abs(num) >= item.value);
    return item
        ? `${formatDisplayWithSoftLimit(num / item.value, 2).replace(regexp, '')}${item.symbol}`
        : '0';
};

export const formatAddressShort = (address: PublicKey | string): string => {
    const str = address.toString();
    return `${str.slice(0, 4)}...${str.slice(-4)}`;
};
