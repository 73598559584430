import * as React from 'react';

import VaultIcon from '../../svg/VaultIcon.svg';

export const Logo: React.FC = () => {
    return (
        <div tw="flex items-center gap-2">
            <VaultIcon tw="w-8 h-8" />
            <span tw="font-semibold text-xl hidden md:block">The Vault</span>
        </div>
    );
};
