import { PoolInfo, PoolQuarryInfo } from './pools';
import { createContainer } from 'unstated-next';
import invariant from 'tiny-invariant';

const usePoolInfoInner = ({ poolInfo }: { poolInfo?: PoolInfo } = {}) => {
    invariant(poolInfo, 'pool info is required');
    const primaryPoolInfo = {
        ...poolInfo.primaryRewards,
        stakedToken: poolInfo.stakedToken,
        isReplica: false,
    };
    const allPools: PoolQuarryInfo[] = [
        primaryPoolInfo,
        ...(poolInfo.secondaryRewards?.map((rew) => ({
            ...rew,
            stakedToken: poolInfo.stakedToken,
            isReplica: true,
        })) ?? []),
    ];
    return {
        poolInfo,
        primaryPoolInfo,
        allPools,
    };
};

export const { Provider: PoolInfoProvider, useContainer: usePoolInfo } =
    createContainer(usePoolInfoInner);
