import React, { useEffect, useMemo, useState } from 'react';
import {
    ConnectionProvider,
    WalletProvider,
} from '@solana/wallet-adapter-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import useNetwork from '../hooks/useNetwork';
import { LocationProvider } from '../hooks/useLocation';
import { PageProps } from 'gatsby';
import { DappLayout } from '../components/dapp/layout/DappLayout';
import { SailProvider } from '@rockooor/sail';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const Dapp = <T extends PageProps>(props: {
    Component: React.FC<T>;
    location: Location;
    children: React.ReactElement<T>;
}) => {
    const { network, endpoint } = useNetwork();

    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SolflareWalletAdapter(),
            new LedgerWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network],
    );

    const [ready, setReady] = useState(false);

    useEffect(() => {
        setReady(true);
    }, []);

    if (!ready) {
        return null;
    }

    return (
        <LocationProvider location={props.location}>
            <QueryClientProvider client={queryClient}>
                <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect>
                        <WalletModalProvider>
                            <SailProvider>
                                <DappLayout>{props.children}</DappLayout>
                            </SailProvider>
                        </WalletModalProvider>
                    </WalletProvider>
                </ConnectionProvider>
            </QueryClientProvider>
        </LocationProvider>
    );
};

export default function dapp<T extends PageProps>(
    WrappedComponent: React.ReactElement<T>,
    props: T,
) {
    return <Dapp {...props}>{WrappedComponent}</Dapp>;
}
