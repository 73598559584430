import { QUARRY_ADDRESSES } from '@quarryprotocol/quarry-sdk';
import { isPublicKey } from '@saberhq/solana-contrib';
import { PublicKey } from '@solana/web3.js';

const findPDASync = (
    seeds: (string | Uint8Array | Buffer | PublicKey)[],
    programId: PublicKey,
): PublicKey => {
    const [addr] = PublicKey.findProgramAddressSync(
        seeds.map((seed) => {
            if (typeof seed === 'string') {
                return Buffer.from(seed, 'utf-8');
            } else if (isPublicKey(seed)) {
                return seed.toBytes();
            } else {
                return seed;
            }
        }),
        programId,
    );
    return addr;
};

export const findRewarderAddress = (
    base: PublicKey,
    programID: PublicKey = QUARRY_ADDRESSES.Mine,
): PublicKey => {
    return findPDASync(['Rewarder', base], programID);
};

export const findQuarryAddress = (
    rewarder: PublicKey,
    tokenMint: PublicKey,
    programID: PublicKey = QUARRY_ADDRESSES.Mine,
): PublicKey => {
    return findPDASync(['Quarry', rewarder, tokenMint], programID);
};

export const findMinerAddress = (
    quarry: PublicKey,
    authority: PublicKey,
    programID: PublicKey = QUARRY_ADDRESSES.Mine,
): PublicKey => {
    return findPDASync(['Miner', quarry, authority], programID);
};

/**
 * Finds the address of the Pool.
 * @returns
 */
export const findMergePoolAddress = ({
    programId = QUARRY_ADDRESSES.MergeMine,
    primaryMint,
}: {
    programId?: PublicKey;
    primaryMint: PublicKey;
}): PublicKey => {
    return findPDASync(['MergePool', primaryMint], programId);
};

/**
 * Finds the address of the Pool.
 * @returns
 */
export const findReplicaMintAddress = ({
    programId = QUARRY_ADDRESSES.MergeMine,
    primaryMint,
}: {
    programId?: PublicKey;
    primaryMint: PublicKey;
}): PublicKey => {
    const pool = findMergePoolAddress({ programId, primaryMint });
    return findPDASync(['ReplicaMint', pool], programId);
};

/**
 * Finds the address of the Merge Miner.
 * @returns
 */
export const findMergeMinerAddress = ({
    programId = QUARRY_ADDRESSES.MergeMine,
    pool,
    owner,
}: {
    programId?: PublicKey;
    pool: PublicKey;
    owner: PublicKey;
}): PublicKey => {
    return findPDASync(['MergeMiner', pool, owner], programId);
};
