import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { css } from 'styled-components';
import tw from 'twin.macro';
import DarkToggler from '../DarkToggler';
import { Logo } from '../common/Logo';
import { MoreInfo } from '../dapp/layout/MoreInfo';
import { SimpleButton } from '../common/ui';

const NavLink: React.FC<{ to: string; label: string }> = ({ to, label }) => (
    <Link
        to={to}
        activeClassName="active"
        css={css`
            ${tw`font-medium text-sm md:text-base border-b-2 border-transparent transition-colors px-2 py-1`}
            &.active {
                ${tw`border-vault-dark`}
            }
            &:not(.active) {
                ${tw`text-secondary`}
                &:hover {
                    ${tw`text-black dark:text-white`}
                }
            }
        `}
    >
        {label}
    </Link>
);

export const Navbar: React.FC = () => {
    const [scrolledFromTop, setScrolledFromTop] = useState(false);

    const onScroll = (e: Event) => {
        setScrolledFromTop(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (e.target.documentElement as Document).scrollTop > 10,
        );
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    }, []);

    return (
        <header
            tw="top-0 z-50 flex w-full items-center fixed transition-all"
            css={[scrolledFromTop && tw`bg-cultured/80 dark:bg-black/80`]}
        >
            <div tw="w-full mx-auto px-2 md:px-5">
                <div tw="relative -mx-4 flex gap-2 items-center">
                    <div tw="px-4">
                        <Link
                            to="/"
                            tw="w-full py-2 md:py-5 flex items-center gap-1"
                        >
                            <Logo />
                        </Link>
                    </div>
                    <nav tw="flex items-center">
                        <div tw="mx-auto pointer-events-auto flex items-center gap-2 md:(ml-10 gap-4)">
                            <NavLink to="/dapp" label="Stake" />
                            <NavLink to="/dapp/unstake" label="Unstake" />
                            <MoreInfo />
                        </div>
                    </nav>
                    <div tw="flex gap-2 items-center grow justify-end">
                        <div tw="hidden md:flex">
                            <DarkToggler />
                        </div>
                        <div tw="flex items-center justify-end px-4">
                            <div tw="flex justify-end">
                                <div tw="flex">
                                    <Link to="/dapp">
                                        <SimpleButton>Dapp</SimpleButton>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
