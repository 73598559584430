import {
    MinerData,
    MinerWrapper,
    QUARRY_CODERS,
    QuarrySDK,
    QuarryWrapper,
} from '@quarryprotocol/quarry-sdk';
import {
    useAnchorWallet,
    useConnection,
    useWallet,
} from '@solana/wallet-adapter-react';
import { Keypair, PublicKey } from '@solana/web3.js';
import { useMemo } from 'react';
import {
    ProgramAccount,
    SignerWallet,
    SolanaProvider,
} from '@saberhq/solana-contrib';
import { POINTS_TOKEN } from '../constants';
import { Token, TokenAmount } from '@saberhq/token-utils';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import {
    findMergeMinerAddress,
    findMergePoolAddress,
    findMinerAddress,
    findQuarryAddress,
    findReplicaMintAddress,
} from './quarry/addresses';
import {
    PoolInfo,
    PoolQuarryInfo,
    PoolRewardsInfo,
} from '../components/dapp/pages/PoolsPage/pools';
import { FetchKeysFn, SailAccountParseError, useSail } from '@rockooor/sail';

export function useProvider() {
    const wallet = useAnchorWallet();
    const { connection } = useConnection();

    const provider = useMemo(() => {
        return SolanaProvider.init({
            connection,
            wallet: wallet ?? new SignerWallet(Keypair.generate()),
        });
    }, [connection, wallet]);

    return {
        connected: !!wallet,
        provider,
    };
}

export function useQuarrySDK() {
    const { provider, connected } = useProvider();
    const quarrySDK = useMemo(() => {
        return QuarrySDK.load({
            provider,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider, connected]);
    return quarrySDK;
}

export const useMiner = (poolInfo: PoolInfo) => {
    const quarry = useQuarrySDK();
    const { stakedToken, primaryRewards } = poolInfo;
    return useQuery({
        queryKey: [
            'miner',
            {
                token: stakedToken.address,
                userPublicKey: quarry.provider.wallet.publicKey.toString(),
            },
        ],
        queryFn: async (): Promise<{
            miner: MinerWrapper;
            quarry: QuarryWrapper;
            minerData: MinerData | null;
        } | null> => {
            if (!quarry) {
                return null;
            }
            const rewarderW = await quarry.mine.loadRewarderWrapper(
                primaryRewards.rewarderKey,
            );
            const quarryW = await rewarderW.getQuarry(stakedToken);
            const minerW = await quarryW.getMinerActions(
                quarry.provider.wallet.publicKey,
            );
            const minerData = await minerW.program.account.miner.fetchNullable(
                minerW.minerKey,
            );
            return {
                miner: minerW,
                quarry: quarryW,
                minerData,
            };
        },
        refetchInterval: 60_000,
    });
};

export const makeParsedAccountDataQueryBuilder =
    <T>(parser: {
        name: string;
        parse: (data: Buffer, publicKey: PublicKey) => T;
    }) =>
    <U = T>({
        publicKey,
        select = (data: ProgramAccount<T>) => data as unknown as U,
        fetchKeys,
    }: {
        publicKey: PublicKey | null | undefined;
        select?: (data: ProgramAccount<T>) => U;
        fetchKeys: FetchKeysFn;
    }) => {
        return {
            queryKey: [
                'parsedAccountData',
                {
                    // parser: parser.name,
                    publicKey: publicKey?.toString(),
                },
            ],
            queryFn: async (): Promise<ProgramAccount<T> | null> => {
                invariant(publicKey, 'publicKey not provided');
                const [raw] = await fetchKeys([publicKey]);
                if (!raw.data) {
                    return null;
                }
                console.log(raw.data.accountInfo.owner.toString());
                try {
                    return {
                        publicKey,
                        account: parser.parse(
                            raw.data.accountInfo.data,
                            raw.data.accountId,
                        ),
                    };
                } catch (e) {
                    throw new SailAccountParseError(e, raw.data);
                }
            },
            enabled: !!publicKey,
            select(data: ProgramAccount<T> | null) {
                if (!data) {
                    return null;
                }
                return select(data);
            },
        };
    };

export const makeParsedAccountDataHook = <T>(parser: {
    name: string;
    parse: (data: Buffer, publicKey: PublicKey) => T;
}) => {
    const parsedAccountDataQueryBuilder =
        makeParsedAccountDataQueryBuilder(parser);
    return <U = ProgramAccount<T>>({
        publicKey,
        select = (data: ProgramAccount<T>) => data as unknown as U,
    }: {
        publicKey: PublicKey | null | undefined;
        select?: (data: ProgramAccount<T>) => U;
    }) => {
        const { fetchKeys } = useSail();
        return useQuery(
            parsedAccountDataQueryBuilder({
                publicKey,
                select,
                fetchKeys,
            }),
        );
    };
};

const useVaultQuarryDirect = makeParsedAccountDataHook(
    QUARRY_CODERS.Mine.accounts.quarry,
);

export const useQuarryInfo = (pool: PoolQuarryInfo) => {
    const quarryKey = findQuarryAddress(
        pool.rewarderKey,
        pool.isReplica
            ? findReplicaMintAddress({
                  primaryMint: pool.stakedToken.mintAccount,
              })
            : pool.stakedToken.mintAccount,
    );
    return useVaultQuarryDirect({
        publicKey: quarryKey,
        select: ({ publicKey, account }) => {
            return {
                publicKey,
                account,
                quarryKey,
                annualRewardsRate: new TokenAmount(
                    pool.rewardsToken,
                    account.annualRewardsRate,
                ),
                totalDeposits: new TokenAmount(
                    pool.stakedToken,
                    account.totalTokensDeposited,
                ),
            };
        },
    });
};

const useUserMinerDirect = makeParsedAccountDataHook(
    QUARRY_CODERS.Mine.accounts.miner,
);

export const useUserMiner = (poolInfo: PoolInfo) => {
    const { stakedToken, primaryRewards } = poolInfo;
    const { publicKey } = useWallet();
    const minerKey = publicKey
        ? findMinerAddress(
              findQuarryAddress(
                  primaryRewards.rewarderKey,
                  stakedToken.mintAccount,
              ),
              publicKey,
          )
        : null;
    return useUserMinerDirect({
        publicKey: minerKey,
        select: ({ publicKey, account }) => {
            return {
                publicKey,
                account,
                balance: new TokenAmount(stakedToken, account.balance),
            };
        },
    });
};

export type PoolRewardsAddresses = {
    rewarder: PublicKey;
    quarry: PublicKey;
    rewardsToken: Token;
    iouMint: PublicKey | null;
    miner: PublicKey | null;
};

const buildPoolRewardsAddresses = ({
    stakedTokenMint,
    rewards,
    authority,
}: {
    stakedTokenMint: PublicKey;
    rewards: PoolRewardsInfo;
    authority: PublicKey | null;
}): PoolRewardsAddresses => {
    const rewarder = rewards.rewarderKey;
    const quarry = findQuarryAddress(rewarder, stakedTokenMint);
    const miner = authority ? findMinerAddress(quarry, authority) : null;
    return {
        rewarder,
        quarry,
        miner,
        iouMint: rewards.iouMint ?? null,
        rewardsToken: rewards.rewardsToken,
    };
};

const getAllMinersMergePool = ({
    poolInfo,
    owner,
}: {
    poolInfo: PoolInfo & {
        secondaryRewards: readonly PoolRewardsInfo[];
    };
    owner?: PublicKey | null;
}): QuarryMinerAddresses => {
    // first, get the merge miner addresses
    const mergePool = findMergePoolAddress({
        primaryMint: poolInfo.stakedToken.mintAccount,
    });
    const mergeMiner = owner
        ? findMergeMinerAddress({
              pool: mergePool,
              owner,
          })
        : null;
    const mergeMine = {
        pool: mergePool,
        mergeMiner,
    };

    // first, primary miner
    const pools: NonEmptyArray<PoolRewardsAddresses> = [
        buildPoolRewardsAddresses({
            stakedTokenMint: poolInfo.stakedToken.mintAccount,
            rewards: poolInfo.primaryRewards,
            authority: mergeMiner,
        }),
    ];

    // next, all replicas
    const replicaMint = findReplicaMintAddress({
        primaryMint: poolInfo.stakedToken.mintAccount,
    });
    poolInfo.secondaryRewards.forEach((secondary) => {
        pools.push(
            buildPoolRewardsAddresses({
                stakedTokenMint: replicaMint,
                rewards: secondary,
                authority: mergeMiner,
            }),
        );
    });

    return { pools, mergeMine };
};

export type NonEmptyArray<T> = [T, ...T[]];

type QuarryMinerAddresses = Readonly<{
    pools: NonEmptyArray<PoolRewardsAddresses>;
    mergeMine?: Readonly<{
        pool: PublicKey;
        mergeMiner: PublicKey | null;
    }>;
}>;

export const getQuarryMinerAddresses = ({
    poolInfo,
    owner = null,
}: {
    poolInfo: PoolInfo;
    owner?: PublicKey | null;
}): QuarryMinerAddresses => {
    if (poolInfo.secondaryRewards) {
        return getAllMinersMergePool({
            poolInfo: poolInfo as PoolInfo & {
                secondaryRewards: readonly PoolRewardsInfo[];
            },
            owner,
        });
    }

    // not merge mine, just do basic quarry
    return {
        pools: [
            buildPoolRewardsAddresses({
                stakedTokenMint: poolInfo.stakedToken.mintAccount,
                rewards: poolInfo.primaryRewards,
                authority: owner,
            }),
        ],
    };
};

/**
 * Hook for getting the user's miner which supports the merge miner.
 * @param poolInfo
 * @returns
 */
export const useUserPrimaryMiner = (poolInfo: PoolInfo) => {
    const { publicKey } = useWallet();
    const { pools, mergeMine } = useMemo(() => {
        return getQuarryMinerAddresses({ poolInfo, owner: publicKey });
    }, [poolInfo, publicKey]);

    const minerKey = pools.find((p) =>
        p.rewardsToken.equals(POINTS_TOKEN),
    )?.miner;
    console.log({ pools, minerKey });
    return {
        pools,
        mergeMine,
        ...useUserMinerDirect({
            publicKey: minerKey,
            select: ({ publicKey, account }) => {
                return {
                    publicKey,
                    account,
                    balance: new TokenAmount(
                        poolInfo.stakedToken,
                        account.balance,
                    ),
                };
            },
        }),
    };
};

/**
 * Hook for getting the user's miner which supports the merge miner.
 * @param poolInfo
 * @returns
 */
export const useUserVPTSMiner = (poolInfo: PoolInfo) => {
    const { publicKey } = useWallet();
    const { pools, mergeMine } = useMemo(() => {
        return getQuarryMinerAddresses({ poolInfo, owner: publicKey });
    }, [poolInfo, publicKey]);

    const vptsAddresses = useMemo(() => {
        return pools.find((p) => p.rewardsToken.equals(POINTS_TOKEN));
    }, [pools]);

    const { data: quarryInfo } = useVaultQuarryDirect({
        publicKey: vptsAddresses?.quarry,
        select: ({ publicKey, account }) => {
            return {
                publicKey,
                account,
                quarryKey: vptsAddresses?.quarry,
                annualRewardsRate: new TokenAmount(
                    POINTS_TOKEN,
                    account.annualRewardsRate,
                ),
                totalDeposits: new TokenAmount(
                    poolInfo.stakedToken,
                    account.totalTokensDeposited,
                ),
            };
        },
    });

    return {
        pools,
        mergeMine,
        quarryInfo,
        ...useUserMinerDirect({
            publicKey: vptsAddresses?.miner,
            select: ({ publicKey, account }) => {
                return {
                    publicKey,
                    account,
                    balance: new TokenAmount(
                        poolInfo.stakedToken,
                        account.balance,
                    ),
                };
            },
        }),
    };
};
