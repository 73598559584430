import * as React from 'react';
import VPTSIcon from '../../svg/tokens/VPTS.svg';
import Vault from '../../svg/vault';
import SolanaToken from './icons/SolanaToken.svg';
import { POINTS_TOKEN, VSOL_TOKEN_OBJ } from '../../constants';
import { Token } from '@saberhq/token-utils';
import { FaRegQuestionCircle } from 'react-icons/fa';
import tw, { css } from 'twin.macro';

const iconForAddress = (token: Token): React.ReactNode => {
    if (token.isRawSOL) {
        return <SolanaToken />;
    }
    if (token.equals(VSOL_TOKEN_OBJ)) {
        return <Vault />;
    }
    if (token.equals(POINTS_TOKEN)) {
        return <VPTSIcon />;
    }
    return <img src={token.icon} />;
};

export const TokenIcon: React.FC<{
    token: Token;
    className?: string;
    size?: number;
}> = ({ token, className, size = 16 }) => {
    const icon = iconForAddress(token);
    return (
        <div
            tw="rounded-full overflow-clip bg-black/20 dark:bg-cultured/20 flex items-center justify-center"
            css={css`
                width: ${size}px;
                height: ${size}px;
                & > svg,
                & > img {
                    ${tw`w-full h-full`}
                }
            `}
            className={className}
        >
            {icon ?? <FaRegQuestionCircle />}
        </div>
    );
};
