import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocationProvider } from '../hooks/useLocation';
import { PageProps } from 'gatsby';
import { SiteLayout } from '../components/dapp/layout/SiteLayout';
import { ConnectionProvider } from '@solana/wallet-adapter-react';
import useNetwork from '../hooks/useNetwork';
import { SailProvider } from '@rockooor/sail';

const queryClient = new QueryClient();

const Site = <T extends PageProps>(props: {
    Component: React.FC<T>;
    props: T;
    location: Location;
    children: React.ReactElement<T>;
}) => {
    const [ready, setReady] = useState(false);
    const { endpoint } = useNetwork();

    useEffect(() => {
        setReady(true);
    }, []);

    if (!ready) {
        return null;
    }

    return (
        <LocationProvider location={props.location}>
            <ConnectionProvider endpoint={endpoint}>
                <QueryClientProvider client={queryClient}>
                    <SailProvider>
                        <SiteLayout>{props.children}</SiteLayout>
                    </SailProvider>
                </QueryClientProvider>
            </ConnectionProvider>
        </LocationProvider>
    );
};

export default function site<T extends PageProps>(
    WrappedComponent: React.ReactElement<T>,
    props: T,
) {
    return <Site {...props}>{WrappedComponent}</Site>;
}
