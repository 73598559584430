import React from 'react';

export default function Saber(props: { className?: string; size?: number }) {
    return (
        <svg
            className={props.className}
            width="204"
            height="204"
            viewBox="0 0 204 204"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="102.114"
                cy="102.21"
                r="101.586"
                fill="url(#paint0_linear_1_123)"
            />
            <path
                d="M158.835 131.1L139.916 150.924C139.507 151.357 139.008 151.698 138.452 151.937C137.896 152.176 137.305 152.29 136.704 152.29H46.9991C46.5678 152.29 46.1479 152.165 45.7961 151.937C45.4329 151.71 45.1605 151.38 44.9903 150.993C44.82 150.606 44.7633 150.185 44.8427 149.775C44.9108 149.365 45.1151 148.978 45.3988 148.671L64.3408 128.846C64.7494 128.414 65.2488 128.072 65.7935 127.845C66.3496 127.606 66.9398 127.492 67.5413 127.492H157.235C157.666 127.492 158.086 127.617 158.438 127.845C158.801 128.072 159.073 128.402 159.244 128.789C159.414 129.176 159.471 129.597 159.391 130.007C159.312 130.417 159.119 130.804 158.835 131.111V131.1ZM139.916 91.1767C139.507 90.7442 139.008 90.4028 138.452 90.1638C137.896 89.9248 137.305 89.811 136.704 89.811H46.9991C46.5678 89.811 46.1479 89.9362 45.7961 90.1638C45.4329 90.3914 45.1605 90.7215 44.9903 91.1084C44.82 91.4954 44.7633 91.9164 44.8427 92.3261C44.9108 92.7358 45.1151 93.1228 45.3988 93.43L64.3408 113.255C64.7494 113.687 65.2488 114.029 65.7935 114.256C66.3496 114.495 66.9398 114.609 67.5413 114.609H157.235C157.666 114.609 158.086 114.484 158.438 114.256C158.801 114.029 159.073 113.699 159.244 113.312C159.414 112.925 159.471 112.504 159.391 112.094C159.323 111.684 159.119 111.297 158.835 110.99L139.916 91.1653V91.1767ZM46.9991 76.9397H136.704C137.305 76.9397 137.907 76.8145 138.452 76.5869C139.008 76.3479 139.507 76.0065 139.916 75.574L158.835 55.7491C159.13 55.4419 159.323 55.0663 159.391 54.6452C159.459 54.2355 159.414 53.8144 159.244 53.4275C159.073 53.0406 158.79 52.7219 158.438 52.4829C158.075 52.2553 157.666 52.1301 157.235 52.1301H67.53C66.9285 52.1301 66.3383 52.2553 65.7822 52.4829C65.2261 52.7219 64.738 53.0633 64.3295 53.4844L45.3875 73.332C45.0924 73.6393 44.8995 74.0149 44.8314 74.436C44.7633 74.8457 44.8087 75.2667 44.9789 75.6537C45.1491 76.0406 45.4329 76.3593 45.7847 76.5983C46.1365 76.8259 46.5565 76.951 46.9877 76.951L46.9991 76.9397Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1_123"
                    x1="160.787"
                    y1="179.55"
                    x2="48.8203"
                    y2="14.1849"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF5905" />
                    <stop offset="1" stopColor="#FFB400" />
                </linearGradient>
            </defs>
        </svg>
    );
}
