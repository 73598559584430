import './src/styles/global.css';

import dapp from './src/hoc/dapp';
import site from './src/hoc/site';

export const wrapPageElement = ({
    element,
    props,
}: {
    element: React.ReactElement;
    props: Record<string, unknown> & { location: Location };
}) => {
    if (props.location.pathname.startsWith('/dapp')) {
        return dapp(element, props);
    }

    return site(element, props);
};
