import React, { createContext, useContext } from 'react';
import type { FC, ReactNode } from 'react';

const LocationContext = createContext<Location | null>(null);

interface LocationProviderProps {
    location: Location;
    children: ReactNode;
}

export const LocationProvider: FC<LocationProviderProps> = ({
    location,
    children,
}) => (
    <LocationContext.Provider value={location}>
        {children}
    </LocationContext.Provider>
);

export const useLocation = (): Location => {
    const location = useContext(LocationContext);

    if (location == null) {
        throw new Error('useLocation must be used inside LocationProvider');
    }

    return location;
};
