import { PublicKey } from '@solana/web3.js';
import { Explorer } from './types';
import { ChainId, Token } from '@saberhq/token-utils';

export const explorers = {
    [Explorer.SOLSCAN]: {
        address: 'https://solscan.io/address/',
    },
};

if (!process.env.GATSBY_RPC_URL) {
    throw new Error('GATSBY_RPC_URL is not set');
}
export const GATSBY_RPC_URL = process.env.GATSBY_RPC_URL;

if (!process.env.GATSBY_STAKE_POOL_MINT) {
    throw new Error('GATSBY_STAKE_POOL_MINT is not set');
}
export const GATSBY_STAKE_POOL_MINT = new PublicKey(
    process.env.GATSBY_STAKE_POOL_MINT,
);

if (!process.env.GATSBY_STAKE_POOL_ADDRESS) {
    throw new Error('GATSBY_STAKE_POOL_ADDRESS is not set');
}
export const GATSBY_STAKE_POOL_ADDRESS = new PublicKey(
    process.env.GATSBY_STAKE_POOL_ADDRESS,
);

console.log('Environment:', {
    GATSBY_RPC_URL,
    GATSBY_STAKE_POOL_MINT: GATSBY_STAKE_POOL_MINT.toString(),
    GATSBY_STAKE_POOL_ADDRESS: GATSBY_STAKE_POOL_ADDRESS.toString(),
});

export const QUARRY_CONFIG_OLD = {
    mintWrapper: new PublicKey('8d1MnZe4UspHfsSvT9LnpkShWsP5y72433PEVhosahz9'),
    rewarder: new PublicKey('BKhCscLJiaWRPzuSDDtJ8xStMS5y5iUrBwPHDg1Aa1XJ'),
    pointsTokenMint: new PublicKey(
        'PTS5EXvvvhe5cLApf3oYy3XdVqTsDJKeGXvvxYJTYK3',
    ),
};

export const QUARRY_CONFIG = {
    mintWrapper: new PublicKey('4Kf7FLWtEPPPcUpizxK82QYNoLSPd2fQTiVLSBx5oKqz'),
    rewarder: new PublicKey('9aE2s74qwiyWkRyRtfxKYLTX6XFLdoaTx8UEr6DE8SEd'),
    pointsTokenMint: new PublicKey(
        'vPtS4ywrbEuufwPkBXsCYkeTBfpzCd6hF52p8kJGt9b',
    ),
};

export const POINTS_TOKEN = new Token({
    name: 'Vault Points',
    address: QUARRY_CONFIG.pointsTokenMint.toString(),
    decimals: 6,
    symbol: 'VPTS',
    chainId: ChainId.MainnetBeta,
});

export const VSOL_TOKEN_OBJ = new Token({
    name: 'Vault SOL',
    address: GATSBY_STAKE_POOL_MINT.toString(),
    decimals: 9,
    symbol: 'vSOL',
    chainId: ChainId.MainnetBeta,
});

export const SBR_REWARDER = new PublicKey(
    'rXhAofQCT7NN9TUqigyEAUzV1uLL4boeD8CRkNBSkYk',
);

export const SBR_LP_MINT = new PublicKey(
    'VLPa8SifUUNL6JvzUuLrjgRibkx7iGr5eVpX6MRR4Qx',
);

export const SBR_LP_TOKEN = new Token({
    name: 'Saber vSOL-SOL',
    address: SBR_LP_MINT.toString(),
    decimals: 9,
    symbol: 'vSOL-SOL',
    chainId: ChainId.MainnetBeta,
    logoURI:
        'https://raw.githubusercontent.com/saber-hq/saber-lp-token-list/master/assets/mainnet/VLPa8SifUUNL6JvzUuLrjgRibkx7iGr5eVpX6MRR4Qx/icon.png',
});

export const TWITTER_LINK = 'https://twitter.com/thevaultfinance';

export const DISCORD_LINK = 'https://discord.gg/DQnTNr6vf4';
