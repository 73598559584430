import { Token, getOrCreateATAs } from '@saberhq/token-utils';
import invariant from 'tiny-invariant';
import {
    useMiner,
    useProvider,
    useQuarrySDK,
    useUserVPTSMiner,
} from '../../../../../hooks/useQuarry';
import useTokenBalance from '../../../../../hooks/useTokenBalance';
import { POINTS_TOKEN } from '../../../../../constants';
import { useSendAndConfirmTransaction } from '../../../../../hooks/useSendTransaction';
import { useWallet } from '@solana/wallet-adapter-react';
import { MinerData, Payroll, QuarryData } from '@quarryprotocol/quarry-sdk';
import BN from 'bn.js';
import { useMemo } from 'react';
import { usePoolInfo } from '../poolContext';
import { dedupeDuplicateATAIxs } from '../../../../../helpers/transaction';
import { SABER_IOU_MINT, SBR_MINT, Saber } from '@saberhq/saber-periphery';
import { PublicKey } from '@solana/web3.js';

export const createQuarryPayroll = (quarryData: QuarryData) =>
    new Payroll(
        quarryData.famineTs,
        quarryData.lastUpdateTs,
        quarryData.annualRewardsRate,
        quarryData.rewardsPerTokenStored,
        quarryData.totalTokensDeposited,
    );

const PRECISION_SECONDS = 100_000;

export const makeCalculateClaimableAmount = ({
    rewardsToken,
    quarryData,
    minerData,
}: {
    rewardsToken: Token;
    quarryData: QuarryData;
    minerData: MinerData;
}): {
    rewardsPerSecond: number;
    get: () => number;
} => {
    const payroll = createQuarryPayroll(quarryData);
    const start = Date.now();
    const [startAmount, endAmount] = [0, PRECISION_SECONDS * 1_000].map(
        (offset) => {
            return payroll.calculateRewardsEarned(
                new BN(Math.floor((start + offset) / 1_000)),
                minerData.balance,
                minerData.rewardsPerTokenPaid,
                minerData.rewardsEarned,
            );
        },
    ) as [BN, BN];

    const divisor = 10 ** rewardsToken.decimals;
    const startAmountNumber = startAmount.toNumber() / divisor;
    const rewardsPerSecond =
        endAmount.sub(startAmount).toNumber() / divisor / PRECISION_SECONDS;

    return {
        rewardsPerSecond,
        get: () =>
            startAmountNumber +
            rewardsPerSecond * ((Date.now() - start) / 1_000),
    };
};

type PoolReward = {
    rewardsToken: Token;
    calculateRewards: () => number;
};

type PoolRewardInfo = {
    isMergeMine: boolean;
    rewards: PoolReward[];
};

export const useQuarryClaim = (): {
    claim: () => Promise<void>;
    rewards: PoolRewardInfo;
} => {
    const { poolInfo } = usePoolInfo();
    const { provider } = useProvider();
    const { wallet } = useWallet();
    const { data: balance } = useTokenBalance(poolInfo.stakedToken.mintAccount);
    const { data: minerW } = useMiner(poolInfo);
    const { data: miner, mergeMine, quarryInfo } = useUserVPTSMiner(poolInfo);

    console.log({ quarryInfo, miner });

    const quarrySDK = useQuarrySDK();
    const sendAndConfirmTransaction = useSendAndConfirmTransaction();

    const rewards = useMemo((): PoolReward[] => {
        if (!miner || !quarryInfo) {
            return [
                {
                    rewardsToken: POINTS_TOKEN,
                    calculateRewards: () => 0,
                },
            ];
        }
        const calculator = makeCalculateClaimableAmount({
            rewardsToken: POINTS_TOKEN,
            quarryData: quarryInfo.account,
            minerData: miner.account,
        });
        return [
            {
                rewardsToken: POINTS_TOKEN,
                calculateRewards: calculator.get,
            },
        ];
    }, [miner, quarryInfo]);

    const claim = async () => {
        if (!miner || !wallet?.adapter.publicKey || !balance || !minerW) {
            return;
        }
        if (mergeMine) {
            if (!mergeMine.mergeMiner) {
                // only can withdraw if there's a connected wallet
                return;
            }

            const saber = Saber.load({ provider });
            const redeemer = await saber.loadRedeemer({
                iouMint: SABER_IOU_MINT,
                redemptionMint: new PublicKey(SBR_MINT),
            });
            const { accounts, instructions } = await getOrCreateATAs({
                provider: saber.provider,
                mints: {
                    iou: redeemer.data.iouMint,
                    redemption: redeemer.data.redemptionMint,
                },
                owner: wallet.adapter.publicKey,
            });

            const mergeMiner = await quarrySDK.mergeMine.loadMM({
                mmKey: mergeMine.mergeMiner,
            });
            const claimPrimaryTx = {
                rewardsToken: poolInfo.primaryRewards.rewardsToken,
                ixs: [
                    ...instructions,
                    ...(
                        await mergeMiner.claimPrimaryRewards(
                            poolInfo.primaryRewards.rewarderKey,
                        )
                    ).instructions,
                    await redeemer.redeemAllTokensFromMintProxyIx({
                        iouSource: accounts.iou,
                        redemptionDestination: accounts.redemption,
                        sourceAuthority: wallet.adapter.publicKey,
                    }),
                ],
            };
            const claimReplicaTxs = await Promise.all(
                poolInfo.secondaryRewards?.map(
                    async ({ rewarderKey, rewardsToken }) => {
                        return {
                            rewardsToken,
                            ixs: (
                                await mergeMiner.claimReplicaRewards(
                                    rewarderKey,
                                )
                            ).instructions,
                        };
                    },
                ) ?? [],
            );

            const allTXs = [claimPrimaryTx, ...claimReplicaTxs];

            // claim each reward separately
            for (let i = 0; i < allTXs.length; i++) {
                const { rewardsToken, ixs } = allTXs[i];
                await sendAndConfirmTransaction(
                    `Claim Rewards (${rewardsToken.symbol})`,
                    dedupeDuplicateATAIxs(ixs),
                );
            }
        } else {
            const { instructions } = await getOrCreateATAs({
                provider: quarrySDK.provider,
                mints: {
                    points: POINTS_TOKEN.mintAccount,
                },
                owner: wallet.adapter.publicKey,
            });

            invariant(minerW);

            const claimTx = await minerW.miner.claim();
            const claimIX =
                claimTx.instructions[claimTx.instructions.length - 1];

            await sendAndConfirmTransaction('Claim Points', [
                ...instructions,
                claimIX,
            ]);
        }
    };

    return {
        claim,
        rewards: {
            isMergeMine: false,
            rewards,
        },
    };
};
