import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { DISCORD_LINK, TWITTER_LINK } from '../../../constants';
import React from 'react';
import tw, { css } from 'twin.macro';

export const SocialIcons: React.FC = () => {
    return (
        <div
            tw="fixed z-10 bottom-8 left-8 flex gap-4"
            css={css`
                a {
                    ${tw`text-secondary hover:(text-black dark:text-white) transition-colors`}
                }
            `}
        >
            <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
                <FaTwitter />
            </a>
            <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
                <FaDiscord />
            </a>
        </div>
    );
};
