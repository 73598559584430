import { Token } from '@saberhq/token-utils';
import { PublicKey } from '@solana/web3.js';
import {
    POINTS_TOKEN,
    QUARRY_CONFIG,
    SBR_LP_TOKEN,
    SBR_REWARDER,
    VSOL_TOKEN_OBJ,
} from '../../../../constants';

export type PoolRewardsInfo = {
    rewarderKey: PublicKey;
    rewardsToken: Token;
    iouMint?: PublicKey;
};

export type PoolQuarryInfo = PoolRewardsInfo & {
    isReplica?: boolean;
    stakedToken: Token;
};

export type PoolInfo = Readonly<{
    stakedToken: Token;
    primaryRewards: PoolRewardsInfo;
    secondaryRewards?: readonly PoolRewardsInfo[];
}>;

const VAULT_REWARDS = {
    rewarderKey: QUARRY_CONFIG.rewarder,
    rewardsToken: POINTS_TOKEN,
};

export const SBR_TOKEN = new Token({
    name: 'Saber Protocol Token',
    symbol: 'SBR',
    logoURI:
        'https://cdn.jsdelivr.net/gh/saber-hq/spl-token-icons@master/icons/101/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1.svg',
    decimals: 6,
    address: 'Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1',
    chainId: 101,
    tags: ['saber-hidden'],
    extensions: {
        website: 'https://saber.so',
        twitter: 'https://twitter.com/saber_hq',
        github: 'https://github.com/saber-hq',
        medium: 'https://blog.saber.so',
        discord: 'https://chat.saber.so',
        serumV3Usdc: 'HXBi8YBwbh4TXF6PjVw81m8Z3Cc4WBofvauj5SBFdgUs',
        coingeckoId: 'saber',
    },
});

const SABER_REWARDS = {
    rewarderKey: SBR_REWARDER,
    rewardsToken: SBR_TOKEN,
    iouMint: new PublicKey('iouQcQBAiEXe6cKLS85zmZxUqaCqBdeHFpqKoSz615u'),
};

export const VSOL_POOL = {
    stakedToken: VSOL_TOKEN_OBJ,
    primaryRewards: VAULT_REWARDS,
};

export const SBR_LP_POOL = {
    stakedToken: SBR_LP_TOKEN,
    primaryRewards: SABER_REWARDS,
    secondaryRewards: [VAULT_REWARDS],
};

export const POOLS: PoolInfo[] = [VSOL_POOL, SBR_LP_POOL];
