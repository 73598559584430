import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { useState } from 'react';
import { GATSBY_RPC_URL } from '../constants';

/**
 * Formats the network as a string.
 * @param network
 * @returns
 */
const formatNetwork = (network: WalletAdapterNetwork): string => {
    if (network === WalletAdapterNetwork.Mainnet) {
        return 'mainnet';
    }
    return network;
};

export default function () {
    const [network] = useState(WalletAdapterNetwork.Mainnet);

    const [endpoint] = useState(GATSBY_RPC_URL);

    return { network, formattedNetwork: formatNetwork(network), endpoint };
}
